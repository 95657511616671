import NextImage from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ButtonAsLink, CardCarousel } from '@inkd/ui';
import { CARD_PRICE, GRAY_BLUR_DATA_URL } from '@inkd/utils';
import { CatalogLayout } from '@/layouts';
import {
  useWebSelectCardModalState,
  WebSelectCardModal,
} from '@/components/WebSelectCardModal';
import {
  type FilteredCategory,
  queryAllCategoriesWithRatingFilter,
} from '@inkd/graphql';
import { cx } from 'cva';
import { SVG } from '@inkd/ui';
import { type GeneratedSVGNames, generatedSVGNames } from '@inkd/svgs';
import { ComponentPropsWithoutRef, Fragment, useContext, useMemo } from 'react';
import { CardsAfterDarkContext } from '@/context/cardsAfterDark';
import { useQuery } from '@apollo/client';

type FilteredCategories = FilteredCategory[] | null;

export default function HomePage() {
  const router = useRouter();
  const context = useContext(CardsAfterDarkContext);
  const { selectedCard, setSelectedCard, modalOpen, setModalOpen } =
    useWebSelectCardModalState();

  const { data: { categories } = {}, loading } = useQuery(
    queryAllCategoriesWithRatingFilter,
    {
      variables: {
        input: {
          categoryType: 'SUB',
        },
        maxRating: 4,
        cardsAfterDarkEnabled: context?.isCardsAfterDark,
      },
    }
  );

  const categoriesForCarousel = useMemo(
    () =>
      categories
        ?.filter(
          category =>
            category &&
            category.cards &&
            category.cards.length > 0 &&
            (category.featured || category.seasonal)
        )
        .sort((categoryA, categoryB) => {
          if (categoryA?.seasonal && !categoryB?.seasonal) {
            return -1;
          }
          if (!categoryA?.seasonal && categoryB?.seasonal) {
            return 1;
          }
          return 0;
        }),
    [categories]
  ) as FilteredCategories;

  const featuredCategories = useMemo(
    () => categoriesForCarousel?.filter(category => category.featured),
    [categoriesForCarousel]
  ) as FilteredCategories;

  const dataLoaded = !loading && !!categoriesForCarousel;

  return (
    <CatalogLayout errorText='Loading cards...'>
      {dataLoaded ? (
        <Fragment>
          <nav aria-label='Featured categories'>
            <ul className='p-i-12 p-bs-2 p-be-10 bg-surface-secondary grid grid-cols-3 gap-x-6 gap-y-2'>
              {featuredCategories?.map(category => (
                <NavItem
                  key={category.id}
                  type='link'
                  text={category.categoryName}
                  href={`/category/${category.id}`}
                  iconName={generatedSVGNames.find(
                    svgName => svgName === category.categoryName
                  )}
                />
              ))}
              <NavItem
                type='link'
                text='All Categories'
                href='/all-categories'
                iconName='Ellipsis'
              />
            </ul>
          </nav>
          <section>
            {categoriesForCarousel ? (
              categoriesForCarousel.map(category => (
                <CardCarousel
                  key={category.id}
                  cardHeight={157}
                  cardWidth={112}
                  cards={category.cards}
                  categoryName={category.categoryName}
                  href={`/category/${category.id}`}
                  NextLink={NextLink}
                  handleClick={selectCardModalProps => {
                    setSelectedCard(selectCardModalProps);
                    setModalOpen(true);
                  }}
                  imageRenderer={props => (
                    <NextImage
                      {...props}
                      blurDataURL={GRAY_BLUR_DATA_URL}
                      placeholder='blur'
                    />
                  )}
                />
              ))
            ) : (
              <div className='p-i-3 p-b-10'>
                <p className='font-bold'>Loading cards...</p>
              </div>
            )}
          </section>
          <div
            // the `block-start` (top) padding needs to be removed if we have an odd number
            // of categories and its background matches the background of the container
            // (white), so that the "all categories" button doesn't look out of place
            className={`p-is-3 ${
              categoriesForCarousel && categoriesForCarousel.length % 2 === 0
                ? 'p-b-10'
                : 'p-be-10'
            }`}
          >
            <ButtonAsLink
              className='max-w-max'
              href='/all-categories'
              icon='ArrowRight'
              NextLink={NextLink}
            >
              All categories
            </ButtonAsLink>
          </div>
          <WebSelectCardModal
            modalOpen={modalOpen}
            selectedCard={selectedCard}
            price={CARD_PRICE}
            handleCancel={() => {
              setModalOpen(false);
            }}
            handleConfirm={async () => {
              await router
                .push({
                  pathname: '/personalize',
                  query: { cId: selectedCard.cardId },
                })
                .then(() => setModalOpen(false));
            }}
          />
        </Fragment>
      ) : null}
    </CatalogLayout>
  );
}

interface ButtonNavItem {
  onClick: ComponentPropsWithoutRef<'button'>['onClick'];
  href?: never;
  type: 'button';
}
interface LinkNavItem {
  onClick?: ComponentPropsWithoutRef<'a'>['onClick'];
  href: string;
  type: 'link';
}
export type NavItemProps = {
  className?: string;
  text: string;
  iconName: GeneratedSVGNames | undefined;
} & (ButtonNavItem | LinkNavItem);

/**
 * A component that handles creating navigation items for the web app's homepage of featured
 * categories to show to the user.
 */
function NavItem({
  className,
  href,
  iconName = 'Star',
  onClick,
  type,
  text,
}: NavItemProps) {
  return (
    <li
      key={text}
      className={cx('text-ui-tiny text-primary text-center', className)}
    >
      {type === 'link' ? (
        <NextLink
          href={href}
          className='flex flex-col items-center justify-center gap-2 p-2 -outline-offset-2'
          onClick={onClick}
        >
          <SVG name={iconName} className='h-6 w-6' />
          <span>{text}</span>
        </NextLink>
      ) : (
        <button
          onClick={onClick}
          className='flex w-full flex-col items-center justify-center gap-2 p-2 -outline-offset-2'
        >
          <SVG name={iconName} className='h-6 w-6' />
          <span>{text}</span>
        </button>
      )}
    </li>
  );
}

// export async function getServerSideProps(
//   context: GetServerSidePropsContext
// ): Promise<GetServerSidePropsResult<PageData>> {
//   const { data } = await apolloClient.query({
//     query: queryAllCategoriesWithRatingFilter,
//     variables: {
//       input: {
//         categoryType: 'SUB',
//       },
//     },
//   });

//   // we are filtering out any categories that are undefined, have 0 cards, or are not
//   // featured, so it's fine to coerce this to the `FilteredCategories` type.
//   const categoriesForCarousel = (data?.categories
//     .filter(
//       category =>
//         category &&
//         category.cards &&
//         category.cards.length > 0 &&
//         (category.featured || category.seasonal)
//     )
//     .sort((categoryA, categoryB) => {
//       if (categoryA?.seasonal && !categoryB?.seasonal) {
//         // represents that seasonal is placed before featured
//         return -1;
//       }
//       if (!categoryA?.seasonal && categoryB?.seasonal) {
//         return 1;
//       }
//       return 0;
//     }) ?? null) as FilteredCategories;

//   const featuredCategories = (categoriesForCarousel?.filter(
//     category => category.featured
//   ) ?? null) as FilteredCategories;

//   return {
//     props: {
//       categoriesForCarousel,
//       featuredCategories,
//     },
//   };
// }
