import NextImage from 'next/image';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { SelectCardModal, SelectCardModalProps } from '@inkd/ui';
import { type SelectedCardDetails } from '@inkd/xstate';
import { CARD_PRICE, GRAY_BLUR_DATA_URL } from '@inkd/utils';

type WebSelectCardModalProps = Omit<
  SelectCardModalProps,
  'mainImageRenderer' | 'interiorImageRenderer'
>;

export function WebSelectCardModal({
  modalOpen,
  selectedCard,
  handleCancel,
  handleConfirm,
}: WebSelectCardModalProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient
    ? createPortal(
        <SelectCardModal
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          interiorImageRenderer={props => (
            <NextImage
              {...props}
              blurDataURL={GRAY_BLUR_DATA_URL}
              placeholder='blur'
              width={357}
              height={500}
            />
          )}
          mainImageRenderer={props => (
            <NextImage
              {...props}
              width={357}
              height={500}
              blurDataURL={GRAY_BLUR_DATA_URL}
              placeholder='blur'
            />
          )}
          modalOpen={modalOpen}
          selectedCard={selectedCard}
          price={CARD_PRICE}
        />,
        document.body
      )
    : null;
}

export function useWebSelectCardModalState() {
  const [selectedCard, setSelectedCard] = useState<SelectedCardDetails>({
    cardId: '',
    cardName: '',
    previewMainPhotoUrl: '',
    mainImageDescription: undefined,
    previewInteriorPhotoUrl: '',
    interiorImageDescription: undefined,
  });
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return {
    selectedCard,
    setSelectedCard,
    modalOpen,
    setModalOpen,
  };
}
